<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card>
      <v-card-title>
        案例管理
        <AppTooltipBtn
          btn-class="ml-5 my-2"
          icon="mdi-help-circle-outline"
          tooltip="团体报告帮助"
          @click="isShowHelpDialog = true"
        />
        <AppTooltipBtn
          rounded
          color="primary"
          icon="mdi-file-chart-outline"
          label="团体报告"
          tooltip="生成团体报告，只能选择同一个量表进行"
          @click="showGroupReport"
        />
        <AppTooltipBtn
          rounded
          btn-class="ml-5 my-2"
          color="primary"
          icon="mdi-folder-zip-outline"
          label="批量下载报告"
          tooltip="把选中的案例，批量导出报告并下载为zip压缩包"
          @click="showReportBulkDownloadDialog(reportBulkDownloadTypeDict.zip)"
        />
        <AppTooltipBtn
          rounded
          btn-class="ml-5 my-2"
          color="primary"
          icon="mdi-file-download-outline"
          label="合并报告"
          tooltip="把选中的案例，合并为同一个pdf报告并下载"
          @click="
            showReportBulkDownloadDialog(reportBulkDownloadTypeDict.mergedPdf)
          "
        />
        <AppTooltipBtn
          rounded
          btn-class="ml-5 my-2"
          color="primary"
          icon="mdi-bookmark-plus-outline"
          label="批量标记"
          tooltip="批量设置选中行的标记，也可点击每行的标记单元格单独设置标记"
          @click="bulkSetCaseMark"
        />
        <AppTooltipBtn
          rounded
          btn-class="ml-5 my-2"
          color="primary"
          icon="mdi-file-excel-outline"
          label="导出Excel"
          tooltip="根据选中案例的分值导出Excel，案例必须为同一张量表"
          @click="extractCasesToExcel"
        />
        <AppTooltipBtn
          v-if="isAdminRole"
          rounded
          btn-class="ml-5 my-2"
          color="error"
          icon="mdi-delete"
          label="批量删除"
          tooltip="删除所有选中的案例"
          @click="deleteCases(true)"
        />
        <v-spacer></v-spacer>
        <AppTooltipBtn
          text
          rounded
          btn-class="ml-5 my-2"
          color="green"
          icon="mdi-tune"
          :label="isShowFilterPanel ? '关闭案例筛选' : '打开案例筛选'"
          tooltip="打开或关闭筛选面板"
          @click="isShowFilterPanel = !isShowFilterPanel"
        />
      </v-card-title>
      <v-slide-y-transition>
        <v-card-text v-if="isShowFilterPanel">
          <div class="d-flex flex-wrap mx-2">
            <v-select
              class="mr-6 filter-item"
              multiple
              small-chips
              dense
              single-line
              outlined
              clearable
              label="案例完成状态"
              :items="filterCaseStatusSelectItems"
              v-model="caseFilters.testStatusList"
            ></v-select>
            <v-select
              class="mr-6 filter-item"
              multiple
              small-chips
              dense
              single-line
              outlined
              clearable
              :label="deptColumnAlias"
              :items="allDeptNamesFromCaseList"
              v-model="caseFilters.deptNames"
            ></v-select>
            <v-select
              class="mr-4 filter-item"
              multiple
              small-chips
              dense
              single-line
              outlined
              clearable
              label="量表"
              :items="allLbNamesFromCaseList"
              v-model="caseFilters.lbNames"
            ></v-select>
            <AppDateRangePicker
              field-style="margin:0 0 25px 0;"
              outlined
              label="测量日期"
              v-model="caseFilters.dateRange"
            />
            <v-select
              class="mr-4 filter-item"
              multiple
              small-chips
              dense
              single-line
              outlined
              clearable
              label="预警"
              :items="allAlertNamesFromCaseList"
              v-model="caseFilters.alertNames"
            ></v-select>
            <v-text-field
              class="mr-4 filter-item filter-item-person"
              v-model="personFilterValue"
              prepend-inner-icon="mdi-magnify"
              :label="`搜索${personFilterFieldSelected}`"
              outlined
              dense
              single-line
              clearable
            >
              <template v-slot:prepend-inner>
                <v-select
                  class="filter-item-inner-select"
                  dense
                  single-line
                  filled
                  hide-details
                  :items="filterPersonFieldList"
                  v-model="personFilterFieldSelected"
                ></v-select>
              </template>
            </v-text-field>
          </div>
          <AppTooltipBtn
            class="ml-6"
            color="primary"
            label="筛选"
            icon="mdi-filter"
            small
            small-icon
            @click="filterCaseList"
          />
          <AppTooltipBtn
            class="ml-6"
            text
            color="primary"
            label="重置"
            icon="mdi-refresh"
            small
            @click="resetCaseFilters"
          />
        </v-card-text>
      </v-slide-y-transition>
      <v-data-table
        class="mx-4"
        v-model="caseListSelected"
        :headers="caseListHeaders"
        :items="caseList"
        item-key="guid"
        :sort-by="caseSortBy"
        :sort-desc="caseSortDesc"
        multi-sort
        show-select
        :single-select="false"
        :page.sync="caseListPageNum"
        :loading="isCaseListLoading"
        loading-text="正在读取案例列表，请稍候..."
        no-data-text="未找到任何案例"
        no-results-text="未找到任何匹配案例"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageAllText: '所有',
          itemsPerPageText: '每页案例数：',
          itemsPerPageOptions: [5, 10, 20, 50, 100, -1]
        }"
        @toggle-select-all="caseToggleSelectAll"
      >
        <template
          v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }"
        >
          {{
            `共 ${itemsLength} 个案例，本页显示案例 ${pageStart}-${pageStop} ${selectedCaseCountText}`
          }}
        </template>
        <template v-slot:[`item.testPerson.testeeNum`]="{ item }">
          {{
            buildTesteeNumPrefix(item.testPerson.testeeNumPrefix) +
              item.testPerson.testeeNum
          }}
        </template>
        <template v-slot:[`item.caseMark`]="{ item }">
          <v-edit-dialog
            :return-value.sync="item.caseMark"
            large
            save-text="保存"
            cancel-text="取消"
            @save="singleSetCaseMark(item.guid, item.caseMark)"
          >
            <div>{{ item.caseMark }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">更新标记</div>
              <v-text-field
                single-line
                dense
                autofocus
                label="案例标记"
                v-model="item.caseMark"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.alertList`]="{ value }">
          <v-tooltip bottom v-for="(alert, idx) in value" :key="idx">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="!!alert.displayName"
                :color="`${alert.colorHex}`"
                v-on="on"
                v-bind="attrs"
              >
                mdi-record
              </v-icon>
            </template>
            <span>{{ alert.displayName }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.status`]="{ value }">
          <span>{{
            filterCaseStatusDict[value] && filterCaseStatusDict[value].text
          }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <ReportModeMenuBtn
            v-if="item.status === filterCaseStatusDict.completed.value"
            v-model="selectedReportMode"
            :lb-id="item.lbId"
            btn-class="mr-2"
            color="primary"
            icon="mdi-file-document-outline"
            tooltip="查看案例报告"
            @click="showAdminReport(item)"
          />
          <AppTooltipBtn
            v-if="item.status === filterCaseStatusDict.completed.value"
            btn-class="mr-2"
            color="primary"
            icon="mdi-file-table-outline"
            tooltip="查看答题详情"
            @click="showAnswerDetails(item)"
          />
          <AppTooltipBtn
            btn-class="mr-2"
            color="primary"
            icon="mdi-account-edit"
            tooltip="修改案例的个人信息"
            @click="editOneCase(item)"
          />
          <AppTooltipBtn
            v-if="isAdminRole"
            color="red"
            icon="mdi-delete"
            tooltip="删除这个案例"
            @click="deleteCases(false, item)"
          />
        </template>
      </v-data-table>
    </v-card>
    <AppDialog
      v-model="isShowEditDialog"
      persistent
      size="small"
      title="编辑案例个人信息"
      text-class="px-10"
      color="green"
      action-text="保存"
      :loading="isBtnLoading"
      @confirm="editOneCaseConfirmed"
      @closed="caseEditDialogClosed"
    >
      <PersonInfoEditor
        :user-entity="userEntity"
        :user-guid="userGuid"
        v-model="editingCasePerson"
        :no-update-fields="casePersonNoUpdateFields"
        @update:no-update-fields="casePersonNoUpdateFields = $event"
        :field-config-list="userFieldConfigList"
      />
    </AppDialog>
    <AppDialog
      v-model="isShowDeleteDialog"
      size="small"
      :title="caseDeleteDialogTitle"
      color="red"
      action-text="删除"
      :loading="isBtnLoading"
      @confirm="deleteCasesConfirmed"
      @closed="caseDeleteDialogClosed"
    >
      删除后不可恢复
    </AppDialog>
    <AppDialog
      v-model="isShowCaseMarkDialog"
      size="small"
      title="批量设置案例标记"
      text-class="px-10"
      color="success"
      action-text="确定"
      :loading="isBtnLoading"
      @confirm="bulkSetCaseMarkConfirmed"
      @closed="caseMarkDialogClosed"
    >
      <v-text-field
        label="标记"
        v-model="newCaseMark"
        clearable
        :rules="fieldRules.caseMark"
      ></v-text-field>
    </AppDialog>
    <AppDialog v-model="isShowHelpDialog" title="团体报告帮助">
      <p class="font-weight-bold">点击“团体报告”按钮查看团体报告</p>
      <h4>生成条件：</h4>
      <p>需要选中6个或以上，基于相同量表的测试结果</p>
      <h4>支持生成团体报告的量表：</h4>
      <ul>
        <li>症状自评量表(SCL-90)</li>
        <li>卡特尔16项人格测验（16PF）</li>
      </ul>
    </AppDialog>
    <ReportDisplayerDialog
      v-model="isShowAdminReportDialog"
      :displayer-type="reportDisplayerType"
      :case-guid-list="reportCaseGuidList"
      :report-mode="selectedReportMode"
      @closed="closeAdminReportDialog"
    />
    <ReportBulkDownloadDialog
      v-model="isShowReportBulkDownloadDialog"
      :download-type="reportBulkDownloadType"
      :case-grouped-list="selectedCaseGroupedListForDownload"
      @closed="closeReportBulkDownloadDialog"
    />
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import PersonInfoEditor from "@/components/PersonInfoEditor";
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppDialog from "@/components/AppDialog";
import ReportDisplayerDialog from "@/components/report/ReportDisplayerDialog";
import ReportBulkDownloadDialog from "@/components/report/ReportBulkDownloadDialog";
import AppMessageBox from "@/components/AppMessageBox";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import ReportModeMenuBtn from "@/components/report/ReportModeMenuBtn";
import AppDateRangePicker from "@/components/AppDateRangePicker";
import { mapGetters } from "vuex";
import _ from "lodash";
import {
  fetchCaseList,
  deleteCases,
  saveCaseMark,
  extractExcelFromCases
} from "@/api/case";
import { reportDownloadTypeDict } from "@/utils/constants/report";
import { caseStatusDict } from "@/utils/constants/case";
import { saveEditedPerson } from "@/api/person";
import { getUserFieldConfig } from "@/api/fieldConfig";
import { downloadFile } from "@/utils/download";
import {
  getStartDateFromDateRange,
  getEndDateFromDateRange
} from "@/utils/dateTime";

export default {
  components: {
    PersonInfoEditor,
    AppLoadingSpinner,
    AppDialog,
    ReportDisplayerDialog,
    ReportBulkDownloadDialog,
    AppMessageBox,
    AppTooltipBtn,
    ReportModeMenuBtn,
    AppDateRangePicker
  },

  data() {
    return {
      // loading
      isCaseListLoading: false,
      isBtnLoading: false,
      isLoading: false,
      // case list
      caseList: [],
      caseListSelected: [],
      userFieldConfigList: [],
      caseListHeaders: [],
      caseSortBy: [],
      caseSortDesc: [],
      caseListPageNum: 1,
      // filters
      isShowFilterPanel: true,
      caseFilters: {
        testStatusList: [],
        lbNames: [],
        deptNames: [],
        alertNames: [],
        dateRange: []
      },
      personFilterFieldSelected: "",
      personFilterValue: "",
      // dialogs
      errorMsg: "",
      isShowFilterDateRangeDialog: false,
      isShowHelpDialog: false,
      isShowEditDialog: false,
      isShowDeleteDialog: false,
      isCaseBulkDelete: false,
      isShowAdminReportDialog: false,
      isShowReportBulkDownloadDialog: false,
      isShowGroupReportHelpDialog: false,
      isShowCaseMarkDialog: false,
      reportDisplayerType: "",
      // report
      selectedReportMode: "",
      reportCaseGuidList: [],
      reportBulkDownloadType: "",
      reportBulkDownloadTypeDict: reportDownloadTypeDict,
      // actions
      actionCaseIndex: -1,
      actionCaseGuid: "",
      actionCaseReportId: "",
      actionCasePerson: {},
      editingCasePerson: {},
      casePersonNoUpdateFields: [],
      newCaseMark: "",
      // rules
      fieldRules: {
        caseMark: [
          val => (val || "").length > 0 || "不能设置空标记",
          val => (val || "").length <= 20 || "标记不能超过20个字符"
        ]
      }
    };
  },

  computed: {
    ...mapGetters({
      userGuid: "user/userGuid",
      roles: "user/roles",
      userEntity: "user/userEntity"
    }),
    isAdminRole() {
      return this.roles.includes("admin");
    },
    selectedCaseCountText() {
      if (this.caseListSelected && this.caseListSelected.length) {
        return `，  选中了${this.caseListSelected.length}个案例`;
      }
      return "";
    },
    selectedCaseGroupedListForDownload() {
      let groupedList = _.chain(this.caseListSelected)
        .groupBy("lbId")
        .map((cas, key) => {
          return {
            lbId: Number(key),
            lbDispName: cas[0].lbDispName,
            caseGuidList: cas.map(c => c.guid),
            caseCount: cas.length
          };
        })
        .value();
      return groupedList;
    },
    caseDeleteDialogTitle() {
      if (this.isCaseBulkDelete) {
        return `确定要删除选中的 ${this.caseListSelected.length} 个案例吗？`;
      }
      return `确定要删除案例 ${this.actionCaseReportId} 吗？`;
    },
    deptColumnAlias() {
      let deptConfig = this.userFieldConfigList.filter(
        field => field.fieldName === "deptGuid"
      );
      if (deptConfig && deptConfig.length) {
        return deptConfig[0].fieldAlias;
      }
      return "";
    },
    allDeptNamesFromCaseList() {
      let depts = _.chain(this.caseList)
        .map(c => c.testPerson.deptName)
        .remove(dept => dept)
        .uniq()
        .value();
      depts.push("无");
      return depts;
    },
    allLbNamesFromCaseList() {
      return _.chain(this.caseList)
        .map(c => c.lbDispName)
        .remove(lb => lb)
        .uniq()
        .value();
    },
    allAlertNamesFromCaseList() {
      let alerts = _.chain(this.caseList)
        .map(c => c.alertList)
        .remove(al => al && al.length)
        .flatten()
        .map(a => a.displayName)
        .uniq()
        .value();
      alerts.push("无预警");
      return alerts;
    },
    filterCaseStatusDict() {
      return caseStatusDict;
    },
    filterCaseStatusSelectItems() {
      return _.map(caseStatusDict, cs => cs);
    },
    filterHasCompletedCaseStatus() {
      let hasCompletedStatus = this.caseFilters.testStatusList.includes(
        this.filterCaseStatusDict.completed.value
      );
      let hasNoStatus = this.caseFilters.testStatusList.length < 1;
      return hasCompletedStatus || hasNoStatus;
    },
    filterHasOnlyOneCaseStatus() {
      return this.caseFilters.testStatusList.length === 1;
    },
    filterPersonFieldList() {
      let pFields = this.userFieldConfigList.filter(
        field =>
          field.fieldName !== "age" &&
          field.fieldName !== "deptGuid" &&
          field.isVisibleInTable
      );
      return pFields.map(field => field.fieldAlias);
    },
    filterPersonFieldName() {
      let matchedFieldConfig = this.userFieldConfigList.find(
        fc => fc.fieldAlias === this.personFilterFieldSelected
      );
      return matchedFieldConfig ? matchedFieldConfig.fieldName : "";
    }
  },

  methods: {
    buildTesteeNumPrefix(prefix) {
      return prefix ? `${prefix}-` : "";
    },
    resetCaseSortBy() {
      this.caseSortBy = ["testDate"];
    },
    resetCaseSortDesc() {
      this.caseSortDesc = [true];
    },
    // ============================ Fetch Data =================================
    async fetchCaseListData() {
      try {
        this.isCaseListLoading = true;
        this.caseList = await fetchCaseList(this.userEntity, this.userGuid, {
          testStatusList: this.caseFilters.testStatusList,
          lbNames: this.caseFilters.lbNames,
          departmentNames: this.caseFilters.deptNames
            ? this.caseFilters.deptNames.map(dName =>
                dName === "无" ? null : dName
              )
            : [],
          alertDispNames: this.caseFilters.alertNames
            ? this.caseFilters.alertNames.map(dName =>
                dName === "无预警" ? null : dName
              )
            : [],
          testDateStart: getStartDateFromDateRange(this.caseFilters.dateRange),
          testDateEnd: getEndDateFromDateRange(this.caseFilters.dateRange),
          testPersonInfo: {
            field: this.filterPersonFieldName,
            value: this.personFilterValue
          }
        });
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isCaseListLoading = false;
    },
    async fetchUserFieldConfigList() {
      try {
        this.isCaseListLoading = true;
        this.userFieldConfigList = await getUserFieldConfig(
          this.userEntity,
          this.userGuid
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isCaseListLoading = false;
    },
    resetCaseListHeaders() {
      this.caseListHeaders = [{ text: "编号", value: "reportId" }];
      for (let fieldConfig of this.userFieldConfigList) {
        // 对 deptGuid 来说，直接使用 deptName 是最高效的，可以不用再次获取 deptSelectList
        let fieldName =
          fieldConfig.fieldName === "deptGuid"
            ? "deptName"
            : fieldConfig.fieldName;
        if (fieldConfig.isVisibleInTable) {
          if (fieldConfig.fieldName === "deptGuid") {
            this.caseListHeaders.push({
              text: fieldConfig.fieldAlias,
              value: `testPerson.${fieldName}`
            });
          } else {
            this.caseListHeaders.push({
              text: fieldConfig.fieldAlias,
              value: `testPerson.${fieldName}`
            });
          }
        }
      }
      this.caseListHeaders.push({
        text: "量表",
        value: "lbDispName"
      });
      if (this.filterHasCompletedCaseStatus) {
        // 没有已完成的情况下，不显示测量日期和预警
        this.caseListHeaders.push({
          text: "测量日期",
          value: "testDate",
          sort: (d1, d2) => new Date(d1) - new Date(d2)
        });
        this.caseListHeaders.push({ text: "标记", value: "caseMark" });
        this.caseListHeaders.push({
          text: "预警",
          value: "alertList",
          sort: (a1, a2) => a1.length - a2.length
        });
      }
      if (!this.filterHasOnlyOneCaseStatus) {
        // 只有已完成的情况下，不显示状态
        this.caseListHeaders.push({
          text: "完成状态",
          value: "status"
        });
      }
      this.caseListHeaders.push({
        text: "操作",
        value: "actions",
        sortable: false
      });
    },
    // ============================ Case Filters =================================
    filterCaseList() {
      // 筛选前清空选择，并重置到第一页
      this.caseListSelected = [];
      this.caseListPageNum = 1;
      this.resetCaseListHeaders();
      return this.fetchCaseListData();
    },
    resetCaseFilters() {
      // 筛选前清空选择
      this.caseFilters = {
        testStatusList: [],
        lbNames: [],
        deptNames: [],
        alertNames: [],
        dateRange: []
      };
      this.personFilterValue = "";
      this.resetPersonFilterFieldSelected();
      return this.filterCaseList();
    },
    resetPersonFilterFieldSelected() {
      if (this.filterPersonFieldList && this.filterPersonFieldList.length) {
        this.personFilterFieldSelected = this.filterPersonFieldList[0];
      }
      this.caseFilters.testStatusList = [
        this.filterCaseStatusDict.completed.value
      ];
    },
    // ============================ Case Actions - Select All =================================
    caseToggleSelectAll({ value }) {
      if (value) {
        this.caseListSelected = this.caseList;
      } else {
        this.caseListSelected = [];
      }
    },
    // ============================ Case Actions - Case Mark =================================
    singleSetCaseMark(caseGuid, newMark) {
      return this.updateCaseMark([caseGuid], newMark);
    },
    bulkSetCaseMark() {
      if (this.caseListSelected.length) {
        this.isShowCaseMarkDialog = true;
      }
    },
    caseMarkDialogClosed() {
      this.newCaseMark = "";
    },
    async bulkSetCaseMarkConfirmed() {
      let updateCaseGuids = this.caseListSelected.map(c => c.guid);
      await this.updateCaseMark(updateCaseGuids, this.newCaseMark);
      // 更新显示的列表
      this.caseList.map(c => {
        if (updateCaseGuids.includes(c.guid)) {
          c.caseMark = this.newCaseMark;
        }
        return c;
      });
      this.isShowCaseMarkDialog = false;
    },
    async updateCaseMark(caseList, newMark) {
      try {
        this.isLoading = true;
        await saveCaseMark(caseList, newMark);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    // ============================ Case - Delete =================================
    deleteCases(isBulkDelete, itemToDelete = null) {
      this.isCaseBulkDelete = isBulkDelete;
      if (isBulkDelete && this.caseListSelected.length) {
        this.isShowDeleteDialog = true;
      } else if (!isBulkDelete && itemToDelete) {
        this.assignActionCase(itemToDelete);
        this.isShowDeleteDialog = true;
      }
    },
    async deleteCasesConfirmed() {
      try {
        this.isBtnLoading = true;
        let caseGuidsToDelete = [];
        if (this.isCaseBulkDelete && this.caseListSelected.length) {
          caseGuidsToDelete = this.caseListSelected.map(c => c.guid);
        } else if (!this.isCaseBulkDelete && this.actionCaseGuid) {
          caseGuidsToDelete = [this.actionCaseGuid];
        }
        let caseGuidsDeleted = await deleteCases(caseGuidsToDelete);
        // 更新显示的列表
        if (caseGuidsDeleted && caseGuidsDeleted.length) {
          this.caseList = this.caseList.filter(
            c => !caseGuidsDeleted.includes(c.guid)
          );
        }
        this.isShowDeleteDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isBtnLoading = false;
    },
    caseDeleteDialogClosed() {
      // 重置actionCase
      this.assignActionCase(null);
    },
    // ============================ Case Item Actions - Edit =================================
    assignActionCase(caseItem) {
      this.actionCaseIndex = caseItem ? this.caseList.indexOf(caseItem) : -1;
      this.actionCaseGuid = caseItem ? caseItem.guid : "";
      this.actionCasePerson = caseItem ? caseItem.testPerson : {};
      this.actionCaseReportId = caseItem ? caseItem.reportId : "";
    },
    editOneCase(item) {
      this.assignActionCase(item);
      this.editingCasePerson = { ...item.testPerson };
      this.isShowEditDialog = true;
    },
    async editOneCaseConfirmed() {
      try {
        this.isBtnLoading = true;
        await saveEditedPerson(
          { ...this.editingCasePerson },
          this.casePersonNoUpdateFields
        );
        // 更新显示的列表
        let casesToUpdate = this.caseList.filter(
          c => c.testPerson.guid === this.editingCasePerson.guid
        );
        for (let c of casesToUpdate) {
          Object.assign(c, { testPerson: this.editingCasePerson });
        }
        this.isShowEditDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isBtnLoading = false;
    },
    caseEditDialogClosed() {
      // 重置actionCase
      this.assignActionCase(null);
      this.editingCasePerson = {};
    },
    // ============================ Case Item Actions - Report =================================
    showAdminReport(item) {
      this.assignActionCase(item);
      this.reportDisplayerType = "admin";
      this.reportCaseGuidList = [this.actionCaseGuid];
      this.isShowAdminReportDialog = true;
    },
    showGroupReport() {
      if (this.caseListSelected.length) {
        this.reportDisplayerType = "group";
        this.reportCaseGuidList = this.caseListSelected.map(c => c.guid);
        this.isShowAdminReportDialog = true;
      }
    },
    showAnswerDetails(item) {
      this.assignActionCase(item);
      this.reportDisplayerType = "answer";
      this.reportCaseGuidList = [this.actionCaseGuid];
      this.isShowAdminReportDialog = true;
    },
    closeAdminReportDialog() {
      this.isShowAdminReportDialog = false;
      this.$nextTick(() => {
        this.assignActionCase(null);
        this.reportDisplayerType = "";
        this.selectedReportMode = "";
      });
    },
    // ============================ 批量下载 pdf (zip/pdf) =================================
    showReportBulkDownloadDialog(downloadType) {
      if (this.caseListSelected.length) {
        this.reportBulkDownloadType = downloadType;
        this.isShowReportBulkDownloadDialog = true;
      }
    },
    closeReportBulkDownloadDialog() {
      this.isShowReportBulkDownloadDialog = false;
      this.reportBulkDownloadType = "";
    },
    // ============================ 导出 Excel =================================
    async extractCasesToExcel() {
      if (this.caseListSelected.length) {
        try {
          this.isLoading = true;
          let caseLbName = "";
          // 必须为同一个量表
          let caseLbNameSet = new Set(
            this.caseListSelected.map(c => c.lbDispName)
          );
          if (caseLbNameSet.size > 1) {
            this.errorMsg = "只能导出同一个量表的案例";
          } else {
            caseLbName = this.caseListSelected[0].lbDispName;
            let caseGuidList = this.caseListSelected.map(c => c.guid);
            let downloadPath = await extractExcelFromCases(
              caseLbName,
              caseGuidList,
              false
            );
            downloadFile(downloadPath, `${caseLbName}.xlsx`);
          }
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isLoading = false;
      }
    }
  },

  async created() {
    await this.fetchUserFieldConfigList();
    // 初始化filter
    this.resetCaseFilters();
    // 初始化sort，不能直接写在template中，任何操作都会跳转回默认排序
    this.resetCaseSortBy();
    this.resetCaseSortDesc();
  }
};
</script>

<style lang="scss" scoped>
$text-field-outlined-prepend-append-margin-top: 0px;

.filter-item {
  max-width: 300px;
  min-width: 200px;
}
.filter-item-person {
  padding-left: 0px;
}
.filter-item-inner-select {
  max-width: 100px;
  min-width: 50px;
  margin: -8px 12px 1px -12px;
}
</style>
