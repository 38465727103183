<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <AppDialog
      v-model="isShowDialog"
      size="large"
      :title="title"
      color="primary"
      :action-text="actionText"
      overflow-height="70vh"
      @confirm="processBulkDownload"
    >
      <v-data-table
        :headers="listHeaders"
        :items="localCaseGroupedList"
        item-key="guid"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.reportMode`]="{ item }">
          <ReportModeSelect
            v-model="item.reportMode"
            :lb-id="item.lbId"
            class="select-medium-width"
          />
        </template>
      </v-data-table>
      <template v-slot:title-right>
        <v-select
          v-if="isEntityHavingCoverPage"
          class="select-medium-width"
          v-model="isIncludeReportCover"
          :items="includeReportCoverSelectItems"
          single-line
          outlined
          dense
          hide-details="auto"
        ></v-select>
      </template>
    </AppDialog>
    <AppDialog
      v-model="isExporting"
      title="正在导出..."
      persistent
      cancel-text="关闭"
    >
      <div>请稍候。正在导出...</div>
      <v-progress-linear rounded indeterminate height="6"></v-progress-linear>
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppDialog from "@/components/AppDialog";
import ReportModeSelect from "@/components/report/ReportModeSelect";
import { mapGetters } from "vuex";
import {
  reportModeDict,
  reportDownloadTypeDict
} from "@/utils/constants/report";
import { bulkSaveReportToZip, bulkSaveReportToSinglePdf } from "@/api/report";
import { fetchCoverPageBody } from "@/api/reportInfo";
import { downloadFile } from "@/utils/download";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppDialog,
    ReportModeSelect
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    downloadType: {
      type: String,
      required: true
    },
    /* lbId: Number
     * lbDispName: String
     * caseGuidList: StringArray
     * caseCount: Number
     */
    caseGroupedList: {
      type: Array,
      required: true
    }
  },

  model: {
    prop: "value",
    event: "change"
  },

  data() {
    return {
      isLoading: false,
      isExporting: false,
      errorMsg: "",
      isShowDialog: this.value,
      title: "",
      actionText: "",
      listHeaders: [
        { text: "量表", value: "lbDispName" },
        { text: "案例数量", value: "caseCount" },
        { text: "导出报告类型", value: "reportMode" }
      ],
      localCaseGroupedList: [],
      downloadTypeDict: reportDownloadTypeDict,
      // report cover
      isEntityHavingCoverPage: false,
      isIncludeReportCover: false,
      includeReportCoverSelectItems: [
        { text: "包含报告封面", value: true },
        { text: "无报告封面", value: false }
      ]
    };
  },

  watch: {
    value(newVal) {
      this.isShowDialog = newVal;
    },
    isShowDialog(newVal) {
      this.$emit("change", newVal);
      if (newVal) {
        this.applyDialogTitle();
        this.applyDefaultReportMode();
      } else {
        this.localCaseGroupedList = [];
        this.$emit("closed");
      }
    }
  },

  computed: {
    ...mapGetters({
      userGuid: "user/userGuid",
      userEntity: "user/userEntity"
    }),
    reportExportListFromCaseList() {
      let reportExportList = [];
      for (let cag of this.localCaseGroupedList) {
        let cagWithMode = cag.caseGuidList.map(cGuid => {
          return {
            caseGuid: cGuid,
            reportModeName: cag.reportMode,
            isIncludeCoverPage: this.isIncludeReportCover
          };
        });
        reportExportList.push(...cagWithMode);
      }
      return reportExportList;
    }
  },

  methods: {
    applyDialogTitle() {
      if (this.downloadType === this.downloadTypeDict.zip) {
        this.title = "批量添加至zip压缩包";
        this.actionText = "压缩并下载";
      } else if (this.downloadType === this.downloadTypeDict.mergedPdf) {
        this.title = "合并到同一个PDF文件";
        this.actionText = "合并下载";
      }
    },
    applyDefaultReportMode() {
      if (this.caseGroupedList) {
        this.localCaseGroupedList = this.caseGroupedList.map(ca => {
          ca.reportMode = reportModeDict.admin.brief;
          return ca;
        });
      }
    },
    async fetchCoverPageHtml() {
      try {
        this.isLoading = true;
        let coverPageHtml = await fetchCoverPageBody(
          this.userEntity,
          this.userGuid
        );
        this.isEntityHavingCoverPage = !!coverPageHtml;
      } catch (err) {
        this.isEntityHavingCoverPage = false;
      }
      // 如果有 cover page，则自动选上
      if (this.isEntityHavingCoverPage) {
        this.isIncludeReportCover = true;
      }
      this.isLoading = false;
    },
    processBulkDownload() {
      if (this.downloadType === this.downloadTypeDict.zip) {
        return this.bulkExportToZipFile();
      } else if (this.downloadType === this.downloadTypeDict.mergedPdf) {
        return this.bulkExportToSinglePdf();
      }
    },
    async bulkExportToZipFile() {
      try {
        this.isExporting = true;
        let downloadPath = await bulkSaveReportToZip(
          this.reportExportListFromCaseList
        );
        downloadFile(downloadPath, "批量报告.zip");
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isExporting = false;
    },
    async bulkExportToSinglePdf() {
      try {
        this.isExporting = true;
        let downloadPath = await bulkSaveReportToSinglePdf(
          this.reportExportListFromCaseList
        );
        downloadFile(downloadPath, "批量合并报告.pdf");
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isExporting = false;
    }
  },

  created() {
    this.fetchCoverPageHtml();
    this.applyDialogTitle();
    this.applyDefaultReportMode();
  }
};
</script>

<style lang="scss" scoped>
.select-medium-width {
  max-width: 200px;
}
</style>
