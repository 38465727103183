import { post } from "@/utils/axios";

export function fetchCaseList(
  userEntity,
  userGuid,
  {
    testStatusList,
    lbNames,
    departmentNames,
    alertDispNames,
    testDateStart,
    testDateEnd,
    testPersonInfo
  }
) {
  let testPerson = {};
  if (testPersonInfo.field) {
    testPerson[testPersonInfo.field] = testPersonInfo.value;
  }
  return post("/case/CaseList", {
    entityType: userEntity,
    entityGuid: userGuid,
    filters: {
      testStatusList,
      lbNames,
      departmentNames,
      alertDispNames,
      testDateStart,
      testDateEnd,
      testPerson
    }
  });
}

export async function deleteCases(caseGuidList) {
  let response = await post("/case/DeleteCaseList", caseGuidList);
  return response.guidList;
}

export async function saveCaseMark(caseGuidList, caseMark) {
  let response = await post("/case/SaveCaseMark", {
    caseGuidList,
    caseMark
  });
  return response.guidList;
}

export async function extractExcelFromCases(
  sheetTitle,
  caseGuidList,
  isBriefScore
) {
  let response = await post("/reportanalyse/ExportScoreToExcel", {
    scoreSheetTitle: sheetTitle,
    caseGuidList,
    isBriefScore
  });
  return response.value;
}
